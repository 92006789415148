<template>
    <div v-if="showBanner" class="cookie-banner d-flex align-items-center justify-content-center" style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 9999;">
        <b-card class="text-center" style="width: 100%;">
            <b-row>
                <b-col cols="12" md="7">
                    <p class="card-text">{{ $t('cookies.description') }}</p>
                </b-col>
                <b-col cols="6" md="2">
                    <b-button @click="acceptCookies" variant="primary">{{ $t('cookies.accept') }}</b-button>
                </b-col>
                <b-col cols="6" md="3">
                    <b-button @click="acceptCookies" variant="secondary">{{ $t('cookies.decline') }}</b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard, BButton, BRow, BCol } from 'bootstrap-vue'

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol
    },
    data() {
        return {
            showBanner: true,
        };
    },
    methods: {
        acceptCookies() {
            this.showBanner = false;
            let fecha = new Date();
            fecha.setTime(fecha.getTime() + (365*24*60*60*1000)); // Cookie expira en 1 año
            let expira = "expires="+ fecha.toUTCString();
            document.cookie = "cookiesAccepted=true;" + expira + ";path=/";
        },
    },
    created() {
        if (document.cookie.split(';').some((item) => item.trim().startsWith('cookiesAccepted='))) {
            this.showBanner = false;
        }
    },
};
</script>