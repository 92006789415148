import Vue from 'vue'
import { externalLogout, manteinance } from '@/main.js'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: '',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(
  response => {
    if(!response.config.url.includes('login')){
      if(response.data.status && response.data.status != 200){
        // Si la respuesta tiene un status, y ese status no es 200, 
        // forzar logout
        if(response.data.status == 401
          || response.data.status == 403){
          externalLogout()
        }
        if(response.data.status == 503){
          manteinance();
        }
      }
    }
    return response // simplemente devuelve la respuesta si todo está bien
  }, 
  error => {
  if(error.response.status == 503){
      manteinance();
    }
    if (error.response && error.response.status === 401) {
      externalLogout()
    }
  }
);

export default axiosIns