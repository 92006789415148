import { encryptData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
    langs: [],
    tableView:false,
  },
  getters: {
    getLocales: state => {
      const { langs } = state
      langs.map(x => {
        x.locale = x.iso_code
      })
      return langs
    },
    getLangs: state => {
      const { langs } = state
      return langs
    },
    getTableView: state => {
      const { tableView } = state
      return tableView
    }
  },
  mutations: {
    setLangs(state, data) {
      state.langs = data
      localStorage.setItem('langs', encryptData(data));
    },
    setTableView(state){
      state.tableView = !state.tableView
    }
  },
  actions: {},
}