import { Ability } from '@casl/ability'
import { initialAbility } from './config'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import { decryptData } from '@/auth/utils'


//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
 
if (store.getters['user/getUserData']?.id == null && localStorage.getItem('userData')) {
    store.commit('user/setToken', localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName))
    store.commit('user/setUser', decryptData(localStorage.getItem('userData')))
    store.commit('general/setLangs', decryptData(localStorage.getItem('langs')))
}

const userData = store.getters['user/getUserData']
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
