import {initialAbility} from '@/libs/acl/config.js'
import { encryptData, decryptData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
    accessToken:"",
    ability: initialAbility,
    avatar: "",
    email: "",
    extras: "",
    fullName: "",
    id: null,
    lang: "",
    role: "",
    username: "",
    school: null,
    max_children:0,
    privacy_accepted:"",
    url_sso:"",
    sso:""
  },
  getters: {
    getRole: state => {
      const { role } = state
      if (role) return role
      return 'user'
    },
    getLang: state => {
      const { lang } = state
      if (lang) return lang
      return 'es'
    },
    geti18n: state => {
      const { lang } = state
      switch (lang) {
        case 'es':
        case 'la':
          return 'es-ES'
          break;
        case 'en':
        case 'tr':
          return 'en-EN'
          break;
        case 'it':
          return 'it-IT'
          break;
        case 'pt':
          return 'pt-PT'
          break;
        case 'fr':
          return 'fr-FR'
          break;
        case 'ca':
          return 'ca-ES'
          break;
        default:
          break;
      }
      if (lang) return lang
      return 'es'
    },
    isSchool: state=>{
        return state.school
    },
    getUserData: state => {
      let userData = {
        ability: state.ability,
        id: state.id,
        fullName: state.fullName,
        username: state.username,
        role: state.role,
        avatar: state.avatar,
        max_children: state.max_children,
        privacy_accepted: state.privacy_accepted,
        url_sso: state.url_sso,
        sso: state.sso
      }
      return userData
    },
    getToken: state => {
      return state.accessToken
    }

  },
  mutations: {
    setToken(state, data){
      state.accessToken = data
      localStorage.setItem('accessToken', data);
    },
    setUser(state, data) {
      state.ability = data.ability
      state.avatar = data.avatar
      state.email = data.email
      state.extras = data.extras
      state.fullName = data.fullName
      state.id = data.id
      state.lang = data.lang
      state.role = data.role
      state.username = data.username
      state.school = data.school
      state.max_children = data.user_max_children
      state.privacy_accepted = data.privacy_accepted
      state.url_sso = data.url_sso
      state.sso = data.sso
      localStorage.setItem('userData', encryptData(data));
    },
    logout(state, data) {
      state.accessToken = ""
      state.ability = []
      state.avatar = ""
      state.email = ""
      state.extras = ""
      state.fullName = ""
      state.id = null
      state.lang = ""
      state.role = ""
      state.username = ""
      state.school = null
      state.privacy_accepted = ""
      //// console.log("store mutation logout", state)
    },
    changeLang(state, value){
      state.lang = value
      let data = decryptData(localStorage.getItem('userData'))
      data.lang = value
      localStorage.setItem('userData', encryptData(data));
    },
    changeURLSSO(state, value){
      state.url_sso = value
      let data = decryptData(localStorage.getItem('userData'))
      data.url_sso = value
      localStorage.setItem('userData', encryptData(data));
    },
    changeName(state, value) {
      state.fullName = value
      let data = decryptData(localStorage.getItem('userData'))
      data.fullName = value
      localStorage.setItem('userData', encryptData(data));
    },
    setPrivacyAccepted(state, value) {
      state.privacy_accepted = value
      let data = decryptData(localStorage.getItem('userData'))
      data.privacy_accepted = value
      localStorage.setItem('userData', encryptData(data));
    },
  },
  actions: {},
}