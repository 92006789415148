<template>
  <div
    class="overlay"
  >
    <div class="sl-modal">
      <h3>{{ times_up ?
        'Session Expired'
        :
        'Your session is about to expire'
        }}</h3>

      <hr>
      <p>{{times_up ?
        `You have left this browser for more than 10 minutes.`
        :
        `You have ${ second } seconds left before your session expires.`
        }}</p>
    </div>
  </div>
</template>
<script>
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
	data() {
		return {
			time: 10000,
      times_up: false,
		}
	},
  created() {
    let timerId = setInterval(() => {
      this.time -= 1000;
      /* if (!this.$store.state.idleVue.isIdle) clearInterval(timerId); */
      if (this.time < 1) {
        clearInterval(timerId);
        // Your logout function should be over here
        this.times_up = true;
        //alert('logout user....');
        this.logout()
      }
    }, 1000);
  },
	computed: {
    second() {
      return this.time / 1000;
    }
  },
  methods: {
    logout() {
      // console.log("LOG OUT!")
      // Remove token from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      //localStorage.removeItem('userData')
      this.$store.commit('user/logout')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
};
</script>
<style lang="css" scoped>
.sl-modal{
  background-color: white;
  width: 500px;
  box-shadow: 1px 2px 4px rgba(153, 155, 168, 0.12);
  border-radius: 4px;
  padding: 20px;
  color: #38404f;
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>