export default [
  {
    path: '/raices/:id',
    name: 'raices',
    component: () => import('@/views/raices/Raices.vue'),
    meta: {
      resource: 'Raíces',
      action: 'read',
    },
  },
]